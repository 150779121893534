import { useSelector } from 'src/hooks/useSelector';
import { PictureType, PictureSettings } from 'src/models/employerConstructor/settings';

const DEFAULT_PICTURE_SETTINGS = {
    [PictureType.GalleryWidget]: {
        minWidth: 690,
        minHeight: 397,
        maxWidth: 10000,
        maxHeight: 10000,
        maxSizeBytes: 6291456,
        allowedMimeTypes: 'image/png,image/jpeg',
        widthHeightRatio: 1.7380352644836272,
    },
    [PictureType.EmployerPageBackground]: {
        minWidth: 1400,
        minHeight: 300,
        maxWidth: 10000,
        maxHeight: 10000,
        maxSizeBytes: 6291456,
        allowedMimeTypes: 'image/png,image/jpeg',
        widthHeightRatio: 4.666666666666667,
    },
    [PictureType.PictureWidget]: {
        minWidth: 690,
        minHeight: 330,
        maxWidth: 10000,
        maxHeight: 10000,
        maxSizeBytes: 6291456,
        allowedMimeTypes: 'image/png,image/jpeg',
        widthHeightRatio: 2.090909090909091,
    },
};

const usePictureSettings = (pictureType: PictureType): PictureSettings => {
    const pictureSettings = useSelector((state) => state.employerConstructorSettings.pictureSettings[pictureType]);
    if (!pictureSettings) {
        /**
         * Если хук вызывается на странице компании при заходе от соискателя или анонима, то никаких настроек нет, возвращаем дефолт, чтобы не падало
         * Надо рефакторить компоненты, чтобы для просмотра со стороны соискателя не вызывались хули для получения настроек конструктора для кропа и т.д.
         */
        return DEFAULT_PICTURE_SETTINGS[pictureType];
    }
    return pictureSettings;
};

export { usePictureSettings };
