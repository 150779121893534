import { UserType } from 'src/models/userType';

import { useSelector } from 'src/hooks/useSelector';

const useIsBackoffice = (): boolean => {
    const isBackoffice = useSelector((state) => state.userType === UserType.BackOffice);
    return isBackoffice;
};

export { useIsBackoffice };
