import { Children, useCallback, useEffect, useState, useRef, useMemo, FC, ReactElement } from 'react';

import RequestAnimation from 'bloko/common/requestAnimation';

import { useScroll } from 'src/hooks/useScroll';

import DragElement from 'src/components/EmployerConstructor/drag/DragElement';
import MovedElementStub from 'src/components/EmployerConstructor/drag/MovedElementStub';
import {
    UniversalDragEvent,
    onMove,
    onDragStart,
    onDragEnd,
    getChildrenKeys,
    DragElements,
    ClickOffset,
    OnDrop,
    OnMove,
} from 'src/components/EmployerConstructor/drag/dragHelpers';

import styles from './drag-container.less';

export interface ContainerProps {
    children: ReactElement[] | ReactElement;
    dropZoneClassName?: string;
    dragElementStubClassName?: string;
    scrollByContainer?: boolean;
    onDrop: (keys: number[]) => void;
    sortKeys: number[];
}

const Container: FC<ContainerProps> = ({
    children,
    dropZoneClassName,
    dragElementStubClassName,
    onDrop,
    sortKeys,
    scrollByContainer,
}) => {
    const onMoveRequestAnimation: OnMove = useMemo(
        () =>
            RequestAnimation((event, params) => {
                onMove(event, {
                    ...params,
                    scrollByContainer,
                });
            }),
        [scrollByContainer]
    );

    const container = useRef<HTMLDivElement>(null);
    const dragElements = useRef<DragElements>([]);
    const lastMouseEvent = useRef<UniversalDragEvent>();
    const dragged = useRef(false);
    const [currentDragIndex, setCurrentDragIndex] = useState<number | null>(null);
    const [dropPositionIndex, setDropPositionIndex] = useState<number | null>(null);
    const [clickOffset, setClickOffset] = useState<ClickOffset>({
        left: 0,
        top: 0,
    });
    const [startPageY, setStartPageY] = useState(0);
    const [movedUp, setMovedUp] = useState(false);

    useEffect(() => {
        const childrenKeys = getChildrenKeys(children);
        if (childrenKeys.length > sortKeys.length) {
            const newKeys = childrenKeys.filter((key) => !sortKeys.includes(key));
            onDrop([...sortKeys, ...newKeys]);
        } else if (childrenKeys.length < sortKeys.length) {
            const filteredKeys = sortKeys.filter((key) => childrenKeys.includes(key));
            onDrop(filteredKeys);
        }
    }, [children, onDrop, sortKeys]);

    const onDropWrapper: OnDrop = useCallback(
        (from, to) => {
            const newSortKeys = [...sortKeys];
            const elementFrom = newSortKeys.splice(from, 1);
            newSortKeys.splice(to, 0, ...elementFrom);
            onDrop(newSortKeys);
        },
        [onDrop, sortKeys]
    );

    const onMoveWrapper = useCallback(
        (event: UniversalDragEvent) => {
            if (dragged.current && event.cancelable) {
                event.preventDefault();
            }
            onMoveRequestAnimation(event, {
                dragged,
                dragElements,
                currentDragIndex,
                lastMouseEvent,
                startPageY,
                container,
                clickOffset,
                setDropPositionIndex,
                setMovedUp,
            });
        },
        [clickOffset, currentDragIndex, onMoveRequestAnimation, startPageY]
    );

    const onScrollMove = useCallback(() => {
        lastMouseEvent.current && onMoveWrapper(lastMouseEvent.current);
    }, [onMoveWrapper]);

    const onDragStartWrapper = useCallback((event: UniversalDragEvent, index: number) => {
        onDragStart(event, {
            index,
            dragged,
            dragElements,
            container,
            setDropPositionIndex,
            setClickOffset,
            setCurrentDragIndex,
            setStartPageY,
        });
    }, []);

    const onDragEndWrapper = useCallback(() => {
        onDragEnd({
            dragged,
            currentDragIndex,
            dropPositionIndex,
            onDrop: onDropWrapper,
            setDropPositionIndex,
            setCurrentDragIndex,
        });
    }, [currentDragIndex, dropPositionIndex, onDropWrapper]);

    useEffect(() => {
        document.addEventListener('mousemove', onMoveWrapper);
        document.addEventListener('touchmove', onMoveWrapper, {
            passive: false,
        });
        return () => {
            document.removeEventListener('mousemove', onMoveWrapper);
            document.removeEventListener('touchmove', onMoveWrapper);
        };
    }, [onMoveWrapper]);

    useScroll(onScrollMove);

    useEffect(() => {
        document.addEventListener('mouseup', onDragEndWrapper);
        document.addEventListener('blur', onDragEndWrapper);
        document.addEventListener('touchend', onDragEndWrapper);
        return () => {
            document.removeEventListener('mouseup', onDragEndWrapper);
            document.removeEventListener('blur', onDragEndWrapper);
            document.removeEventListener('touchend', onDragEndWrapper);
        };
    }, [onDragEndWrapper]);

    return (
        <div className={styles.constructorDragContainer} ref={container}>
            {Children.map(children, (child) => {
                const orderIndex = sortKeys.indexOf(Number(child.key));
                const order = orderIndex < 0 ? sortKeys.length : orderIndex;
                const isActive = currentDragIndex === order;
                const elementIsDropPosition = dropPositionIndex === order;
                return (
                    <div
                        key={child.key}
                        style={{
                            order,
                        }}
                    >
                        {isActive && (
                            <MovedElementStub
                                dragElement={dragElements.current[currentDragIndex]}
                                stubClassName={dragElementStubClassName}
                            />
                        )}
                        {movedUp && !isActive && elementIsDropPosition && <div className={dropZoneClassName} />}
                        <DragElement
                            ref={(ref) => {
                                if (ref) {
                                    dragElements.current[order] = ref;
                                } else {
                                    dragElements.current.splice(order, 1);
                                }
                            }}
                            onDragStart={onDragStartWrapper}
                            index={order}
                            active={isActive}
                            hasDraggedElements={currentDragIndex !== null}
                        >
                            {child}
                        </DragElement>
                        {!movedUp && !isActive && elementIsDropPosition && <div className={dropZoneClassName} />}
                    </div>
                );
            })}
        </div>
    );
};

export default Container;
