import { usePictureSettings } from 'src/components/EmployerConstructor/usePictureSettings';
import { useSelector } from 'src/hooks/useSelector';
import { PictureSettings, PictureType, WidgetSettings } from 'src/models/employerConstructor/settings';

interface UseSettingsResult {
    pictureSettings: PictureSettings;
    widgetSettings?: WidgetSettings;
}

const useSettings = (): UseSettingsResult => {
    const pictureSettings = usePictureSettings(PictureType.GalleryWidget);

    const widgetSettings = useSelector((state) => state.employerConstructorSettings.widgetSettings);

    return { pictureSettings, widgetSettings };
};

export { useSettings };
