import { useCallback, useState, useEffect, MutableRefObject } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import Button, { ButtonKind } from 'bloko/blocks/button';
import { FormSpacer } from 'bloko/blocks/form';
import Gap from 'bloko/blocks/gap';
import Link, { LinkAppearance } from 'bloko/blocks/link';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import Layers from 'bloko/common/constants/layersCssClasses';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { getCurrentAddress, EMPTY_ITEM, PreparedAddress, isNonEmptyAddress } from 'Modules/EmployerAddresses/helpers';
import AddAddressModal from 'src/components/AddressSuggest/AddAddressModal';
import AddressBlokoSuggest from 'src/components/AddressSuggest/AddressBlokoSuggest';
import AddressItem from 'src/components/AddressSuggest/AddressItem';
import ErrorComponent from 'src/components/EmployerConstructor/ErrorComponent';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { useToggleState } from 'src/hooks/useToggleState';
import { EmployerAddress } from 'src/models/employerAddresses.types';
import {
    employerConstructorModifyWidget,
    EmployerConstructorAddressWidget,
    WidgetComponentProps,
} from 'src/models/employerConstructor';
import { fetcher } from 'src/utils/fetcher';

import AddressWidgetMap from 'src/components/EmployerConstructor/widgets/AddressWidgetMap';
import WidgetControls from 'src/components/EmployerConstructor/widgets/WidgetControls';
import WidgetWrapper from 'src/components/EmployerConstructor/widgets/WidgetWrapper';
import WidgetEditDescription from 'src/components/EmployerConstructor/widgets/components/WidgetEditDescription';

import styles from './address-widget.less';

const employerAddressesActions = makeSetStoreField('employerAddresses');

const GET_ADDRESSES_URL = '/employer/addresses/ajax';

declare global {
    interface FetcherGetApi {
        [GET_ADDRESSES_URL]: {
            queryParams: void;
            response: { items: EmployerAddress[] };
        };
    }
}

const TrlKeys = {
    widgetMapName: 'employer.constructor.widgetname.map',
    widgetMapEditText: 'employer.constructor.widgetedit.map',
    description: 'employer.constructor.addresswidget.description',
    uploadButton: 'employer.constructor.addresswidget.uploadButton',
    saveAddress: 'employer.constructor.addresswidget.saveAddress',
    cancelEdit: 'employer.constructor.addresswidget.cancelEdit',
    search: 'employer.constructor.addresswidget.inputPlaceholder',
    metroStation: 'metrostation',
    invalidMessage: 'employer.constructor.addresswidget.invalid',
    addAddress: 'employer.constructor.addresswidget.addaddress',
};

interface AddressWidgetProps extends WidgetComponentProps<EmployerConstructorAddressWidget> {
    addressesLoaded?: boolean;
    setAddressesLoaded: (newValue: boolean) => void;
    addressesFetching: MutableRefObject<boolean>;
}

const AddressWidget: TranslatedComponent<AddressWidgetProps> = ({
    trls,
    editMode,
    addressId,
    id,
    addressesLoaded,
    setAddressesLoaded,
    getMovedElementProps,
    dragged,
    addressesFetching,
}) => {
    const dispatch = useDispatch();
    const addresses = useSelector((state) => state.employerAddresses);
    const remoteSearch = useSelector((state) => state.addressesSuggestRemoteMode);
    const [addAddressModalVisible, addAddressModalToggle] = useToggleState(false);
    const [inited, setInited] = useState(false);
    const [viewItem, setViewItem] = useState(getCurrentAddress(addresses, addressId, trls[TrlKeys.metroStation]));
    const isMagritteEmployerPageHeaderExp = useSelector((state) => state.isMagritteEmployerPageHeaderExp);
    const [selectedItem, setSelectedItem] = useState(viewItem);
    const viewReady = viewItem !== EMPTY_ITEM;

    const [localEditMode, toggleLocalEditMode, setLocalEditMode] = useToggleState(!viewReady);
    const [suggestInputValue, setSuggestInputValue] = useState(selectedItem.text);
    const [invalid, setInvalid] = useState(false);

    const setSelectedItemWrapper = useCallback(
        (address: PreparedAddress) => {
            setSuggestInputValue(address.text);
            setSelectedItem(address);
            if (isNonEmptyAddress(address)) {
                if (!addresses.some((item) => item.id === address.source.id)) {
                    dispatch(employerAddressesActions([...addresses, address.source]));
                }
                setInvalid(false);
            }
        },
        [addresses, dispatch]
    );

    const setSuggestInputValueWrapper = useCallback(
        (inputValue: string) => {
            setSuggestInputValue(inputValue);
            const value = inputValue.trim();
            if (!value) {
                setSelectedItemWrapper(EMPTY_ITEM);
            }
            if (value !== selectedItem.text) {
                setSelectedItem(EMPTY_ITEM);
            }
        },
        [selectedItem.text, setSelectedItemWrapper]
    );

    useEffect(() => {
        if (editMode && localEditMode && !addressesLoaded && !addressesFetching.current) {
            if (!remoteSearch) {
                addressesFetching.current = true;
                fetcher
                    .get('/employer/addresses/ajax')
                    .then((data) => {
                        dispatch(employerAddressesActions(data.items || []));
                        setAddressesLoaded(true);
                        addressesFetching.current = false;
                        return null;
                    })
                    .catch(console.error);
            } else {
                setAddressesLoaded(true);
            }
        }
    }, [addressesFetching, addressesLoaded, dispatch, editMode, localEditMode, remoteSearch, setAddressesLoaded]);
    useEffect(() => {
        if (!inited && addressesLoaded) {
            setInited(true);
            const currentAddress = getCurrentAddress(addresses, addressId, trls[TrlKeys.metroStation]);
            setSelectedItemWrapper(currentAddress);
            setViewItem(currentAddress);
            if (currentAddress === EMPTY_ITEM) {
                setLocalEditMode(true);
            }
        }
    }, [addressId, addresses, addressesLoaded, inited, setLocalEditMode, setSelectedItemWrapper, trls]);

    const cancelEdit = useCallback(() => {
        setSelectedItemWrapper(getCurrentAddress(addresses, addressId, trls[TrlKeys.metroStation]));
        setInvalid(false);
        setLocalEditMode(false);
    }, [addressId, addresses, setLocalEditMode, setSelectedItemWrapper, trls]);

    const saveAddressId = useCallback(() => {
        if (selectedItem === EMPTY_ITEM) {
            setInvalid(true);
            return;
        }
        setViewItem(selectedItem);
        dispatch(
            employerConstructorModifyWidget({
                id,
                addressId: selectedItem.id ? Number(selectedItem.id) : undefined,
            })
        );
        setLocalEditMode(false);
    }, [dispatch, id, selectedItem, setLocalEditMode]);
    const view = useCallback(() => {
        const currentAddress = addresses.find((address) => address.id === Number(addressId))!;
        return (
            <div
                className={classNames(styles.widgetAddressView, {
                    [styles.widgetAddressViewMagritte]: isMagritteEmployerPageHeaderExp,
                })}
            >
                <AddressWidgetMap address={currentAddress} />
                <Gap top left right bottom>
                    <Text size={TextSize.Large}>
                        <AddressItem {...viewItem} />
                    </Text>
                </Gap>
            </div>
        );
    }, [addressId, addresses, isMagritteEmployerPageHeaderExp, viewItem]);

    const edit = useCallback(() => {
        return (
            <>
                <WidgetControls
                    upload={false}
                    edit={!localEditMode}
                    getMovedElementProps={getMovedElementProps}
                    editText={trls[TrlKeys.widgetMapEditText]}
                    onEdit={toggleLocalEditMode}
                    name={trls[TrlKeys.widgetMapName]}
                    id={id}
                />
                {localEditMode && (
                    <WidgetEditDescription>
                        <VSpacing base={2} />
                        <div>{trls[TrlKeys.description]}</div>
                        <div>
                            <VSpacing base={2} />
                            {addressesLoaded && (
                                <AddressBlokoSuggest
                                    addresses={addresses}
                                    remoteSearch={remoteSearch}
                                    address={selectedItem}
                                    inputValue={suggestInputValue}
                                    onSelect={setSelectedItemWrapper}
                                    placeholder={trls[TrlKeys.search]}
                                    invalid={invalid}
                                    setInvalid={setInvalid}
                                    searchOnFocus={true}
                                    selectOnBlur={false}
                                    limit={10}
                                    layer={Layers.Topmost}
                                    inputProps={{
                                        onChange: setSuggestInputValueWrapper,
                                    }}
                                />
                            )}
                            <ErrorComponent show={invalid}>{trls[TrlKeys.invalidMessage]}</ErrorComponent>

                            <VSpacing base={2} />
                            <Button onClick={saveAddressId} kind={ButtonKind.Success}>
                                {!viewReady ? trls[TrlKeys.uploadButton] : trls[TrlKeys.saveAddress]}
                            </Button>
                            {viewReady && (
                                <FormSpacer>
                                    <Button onClick={cancelEdit}>{trls[TrlKeys.cancelEdit]}</Button>
                                </FormSpacer>
                            )}
                            <Gap top>
                                <Link appearance={LinkAppearance.Pseudo} onClick={addAddressModalToggle}>
                                    {trls[TrlKeys.addAddress]}
                                </Link>
                            </Gap>
                        </div>
                        <AddAddressModal
                            onCreate={(rawAddress) => {
                                const newAddresses = [...addresses, rawAddress];
                                const currentAddress = getCurrentAddress(
                                    newAddresses,
                                    rawAddress.id,
                                    trls[TrlKeys.metroStation]
                                );
                                setSelectedItemWrapper(currentAddress);
                                dispatch(employerAddressesActions(newAddresses));
                            }}
                            onSelect={(address) => {
                                const currentAddress = getCurrentAddress(
                                    addresses,
                                    address.id,
                                    trls[TrlKeys.metroStation]
                                );
                                setSelectedItemWrapper(currentAddress);
                            }}
                            onClose={addAddressModalToggle}
                            visible={addAddressModalVisible}
                        />
                    </WidgetEditDescription>
                )}
                {!localEditMode && viewReady && view()}
            </>
        );
    }, [
        addAddressModalToggle,
        addAddressModalVisible,
        addresses,
        addressesLoaded,
        cancelEdit,
        dispatch,
        getMovedElementProps,
        id,
        invalid,
        localEditMode,
        remoteSearch,
        saveAddressId,
        selectedItem,
        setSelectedItemWrapper,
        setSuggestInputValueWrapper,
        suggestInputValue,
        toggleLocalEditMode,
        trls,
        view,
        viewReady,
    ]);

    return <WidgetWrapper dragged={dragged} edit={edit} view={view} editMode={editMode} viewReady={viewReady} />;
};
export default translation(AddressWidget);
