import { useCallback, memo, FC } from 'react';

import WidgetWrapper from 'src/components/EmployerConstructor/widgets/WidgetWrapper';
import { WidgetComponentProps, EmployerConstructorGalleryWidget } from 'src/models/employerConstructor';

import EditModeContainer from 'src/components/EmployerConstructor/widgets/GalleryWidget/EditModeContainer';
import ViewModeContainer from 'src/components/EmployerConstructor/widgets/GalleryWidget/ViewModeContainer';
import { usePictures } from 'src/components/EmployerConstructor/widgets/GalleryWidget/hooks/usePictures';

const GalleryWidget: FC<WidgetComponentProps<EmployerConstructorGalleryWidget>> = ({
    items,
    editMode,
    dragged,
    id,
    getMovedElementProps,
}) => {
    const pictures = usePictures(items);

    const viewRender = useCallback(() => <ViewModeContainer pictures={pictures} />, [pictures]);
    const editRender = useCallback(
        () => <EditModeContainer getMovedElementProps={getMovedElementProps} pictures={pictures} widgetId={id} />,
        [getMovedElementProps, pictures, id]
    );

    return (
        <WidgetWrapper
            dragged={dragged}
            edit={editRender}
            view={viewRender}
            editMode={editMode}
            viewReady={pictures.length > 0}
        />
    );
};

export default memo(GalleryWidget);
