import { useMemo, useState } from 'react';

import ImageSlide from 'src/components/EmployerConstructor/widgets/GalleryWidget/components/ImageSlide';
import {
    SlideData,
    SetActiveSlide,
} from 'src/components/EmployerConstructor/widgets/GalleryWidget/containers/SlidesContainer';

import { PreparedPicture } from 'src/components/EmployerConstructor/widgets/GalleryWidget/hooks/usePictures';

export const SLIDE_TYPE_IMAGE = 'SLIDE_TYPE_IMAGE';
export const SLIDE_TYPE_LOAD_AREA = 'SLIDE_TYPE_LOAD_AREA';

type UseSlidesResult = [SlideData[], number, SetActiveSlide];

const useSlides = (pictures: PreparedPicture[]): UseSlidesResult => {
    const [activeSlide, setActiveSlide] = useState(1);

    const pictureSlides = useMemo(
        () =>
            pictures.map(({ src, id }) => ({
                id: `${SLIDE_TYPE_IMAGE}_${id}`,
                content: <ImageSlide src={src} />,
            })),
        [pictures]
    );

    return [pictureSlides, activeSlide, setActiveSlide];
};

export { useSlides };
