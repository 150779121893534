import { useMemo } from 'react';

import yandexMapsSuggest from '@hh.ru/analytics-js-events/build/xhh/common/yandex_maps/yandex_maps_suggest';
import addressesModalSuggest from '@hh.ru/analytics-js-events/build/xhh/employer/addresses/addresses_modal_suggest';
import { createDataProvider, DataProvider, DataProviderResult, decorateWithResultProducer } from '@hh.ru/magritte-ui';

import { SuggestResponse, YMaps } from 'Modules/Maps/maps.types';

const formatter = (items: SuggestResponse[] | null): DataProviderResult<SuggestResponse> => {
    if (!items) {
        return [];
    }

    return items.map((item) => ({
        type: 'cells',
        items: [
            {
                data: item,
                value: item.value,
            },
        ],
    }));
};

const fetcher = async (
    query: string,
    yMaps: YMaps | null,
    vacancyId: number | undefined,
    vacancyDraftId: number | null
) => {
    if (!yMaps) {
        return [];
    }

    const suggestResponses = await yMaps.suggest(query, { provider: 'yandex#search' });
    addressesModalSuggest({
        query,
        results: suggestResponses?.map((response) => response.value).join('; ') || '',
        vacancyId,
        draftId: vacancyDraftId,
    });
    yandexMapsSuggest();

    return suggestResponses;
};

const useAddressSuggestDataProvider = (
    yMaps: YMaps | null,
    vacancyId: number | undefined,
    vacancyDraftId: number | null
): DataProvider<SuggestResponse> => {
    return useMemo(
        () =>
            createDataProvider({
                fetcher: decorateWithResultProducer(
                    (query) => fetcher(query, yMaps, vacancyId, vacancyDraftId),
                    formatter
                ),
                debounceTimeout: 600,
            }),
        [yMaps, vacancyId, vacancyDraftId]
    );
};

export { useAddressSuggestDataProvider };
