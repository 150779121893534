import { useEffect } from 'react';

const useScroll = (handler: (event: Event) => void): void => {
    useEffect(() => {
        window.addEventListener('scroll', handler);
        return () => {
            window.removeEventListener('scroll', handler);
        };
    }, [handler]);
};

export { useScroll };
