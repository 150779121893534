import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import addBlockButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/branding/employer_page/add_block_button_click';
import { PlusOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import Column from 'bloko/blocks/column';
import Down, { DownPlacement } from 'bloko/blocks/drop/Down';
import NoPaddings from 'bloko/blocks/drop/Down/NoPaddings';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import scrollToElement from 'Utils/ScrollToElement';
import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';
import translation from 'src/components/translation';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { useToggleState } from 'src/hooks/useToggleState';
import { EmployerConstructorWidget } from 'src/models/employerConstructor';
import { Widget } from 'src/models/employerConstructor/widget.types';

import AddWidgetButton from 'src/components/EmployerConstructor/AddWidgetButton';

import widgetsStyles from 'src/components/EmployerConstructor/widgets/employer-constructor-widgets.less';

import styles from './add-widget.less';

const WIDGETS_TYPES_DEFAULT = [
    Widget.Text,
    Widget.Picture,
    Widget.Gallery,
    Widget.Video,
    Widget.Address,
    Widget.Separator,
] as const;
const WIDGETS_TYPES_FOR_ZARPLATA = [Widget.Gallery] as const;
const setAutoAddWidget = makeSetStoreField('employerConstructorAutoAddWidget');

const widgetTypeButtonNameMap = {
    [Widget.Text]: 'add_widget_text',
    [Widget.Picture]: 'add_widget_picture',
    [Widget.Gallery]: 'add_widget_gallery',
    [Widget.Video]: 'add_widget_video',
    [Widget.Address]: 'add_widget_address',
    [Widget.Separator]: 'add_widget_separator',
} as const;

interface AddWidgetProps {
    widgets: EmployerConstructorWidget[];
    onClick: (data: EmployerConstructorWidget) => void;
    disableToggleOpened?: boolean;
}

const TrlKeys = {
    addWidget: 'employer.constructor.addBlock',
};

const AddWidget: TranslatedComponent<AddWidgetProps> = ({ trls, widgets, onClick, disableToggleOpened }) => {
    const [dropDownHost, setDropDownHost] = useState<HTMLDivElement | null>(null);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const isZP = useIsZarplataPlatform();
    const activatorRef = useRef(null);
    const widgetTypes = isZP ? WIDGETS_TYPES_FOR_ZARPLATA : WIDGETS_TYPES_DEFAULT;
    useEffect(() => {
        setDropDownHost(wrapperRef.current);
    }, [setDropDownHost]);

    const { countSettings, autoAddWidget } = useSelector(
        ({ employerConstructorSettings, employerConstructorAutoAddWidget }) => ({
            countSettings: employerConstructorSettings?.widgetSettings?.maxCounts,
            autoAddWidget: employerConstructorAutoAddWidget,
        })
    );
    const maxWidgetId = Math.max(...widgets.map(({ id }) => id), 0);
    const [opened, toggleOpened] = useToggleState(true);

    useEffect(() => {
        if (autoAddWidget) {
            dispatch(setAutoAddWidget(null));
            onClick({ type: autoAddWidget, id: maxWidgetId + 1 });
            scrollToElement(wrapperRef.current);
        }
    }, [autoAddWidget, dispatch, maxWidgetId, onClick]);

    const renderBlock = () => (
        <NoPaddings>
            <div className={styles.addWidgetColumnWrapper}>
                <Column xs="4" s="8" m="9" l="11">
                    <div className={styles.addWidgetContent}>
                        {widgetTypes.map((type) => {
                            const widgetsCount = widgets.filter((widget) => widget.type === type)?.length;
                            return (
                                <AddWidgetButton
                                    key={type}
                                    limit={countSettings?.[type]}
                                    count={widgetsCount}
                                    type={type}
                                    onClick={() => {
                                        const buttonName = widgetTypeButtonNameMap[type];
                                        addBlockButtonClick({ buttonName });
                                        onClick({ type, id: maxWidgetId + 1 });
                                        !disableToggleOpened && toggleOpened();
                                    }}
                                />
                            );
                        })}
                    </div>
                </Column>
            </div>
        </NoPaddings>
    );

    return (
        <div ref={wrapperRef} className={styles.addWidgetWrapper}>
            <div className={widgetsStyles.employerConstructorWidget}>
                <Down
                    show={opened}
                    placement={DownPlacement.BottomStart}
                    onlySetPlacement={true}
                    render={renderBlock}
                    flexible={true}
                    showCloseButton={false}
                    host={dropDownHost}
                    activatorRef={activatorRef}
                >
                    <div
                        className={styles.addWidget}
                        onClick={() => {
                            toggleOpened();
                            addBlockButtonClick({ buttonName: 'add_any_block' });
                        }}
                        data-qa="addwidget-activator"
                        ref={activatorRef}
                    >
                        <span className={styles.addWidgetPlus}>
                            <BlokoIconReplaceContainer>
                                <PlusOutlinedSize16 initialColor="accent" />
                            </BlokoIconReplaceContainer>
                        </span>{' '}
                        {trls[TrlKeys.addWidget]}
                    </div>
                </Down>
            </div>
        </div>
    );
};

export default translation(AddWidget);
