import { useState, FC, useCallback } from 'react';

import Button, { ButtonKind } from 'bloko/blocks/button';
import Gap from 'bloko/blocks/gap';
import Loading, { LoadingScale, LoadingColor } from 'bloko/blocks/loading';
import Modal, { ModalTitle, ModalHeader, ModalFooter } from 'bloko/blocks/modal';

import { useToggleState } from 'src/hooks/useToggleState';

import ImageCrop, { CropResult, ImageCropProps } from 'src/components/ImageCropPopup/ImageCrop';
import ModalError from 'src/components/ImageCropPopup/ModalErrorWithHandlingErrors';

import styles from './image-crop-popup.less';

export type ImageCropResultHandler = (cropResult: CropResult) => void;

interface ImageCropPopupProps {
    onClose?: ImageCropResultHandler;
    onSave?: ImageCropResultHandler;
    onDragStop?: () => void;
    visible?: boolean;
    resizeInProgress?: boolean;
    imageCropSettings: ImageCropProps;
    error?: string;
    title: string;
    description: string;
    save: string;
    cancel: string;
}

const ImageCropPopup: FC<ImageCropPopupProps> = ({
    onClose,
    onDragStop,
    onSave,
    visible,
    resizeInProgress,
    imageCropSettings,
    title,
    description,
    save,
    cancel,
    error,
}) => {
    const [cropImageParams, setCropImageParams] = useState<CropResult | null>(null);
    const [dragged, , setDragged] = useToggleState(false);

    /** с таймаутом чтобы в момент, когда зона выделения тянется дальше зоны модалки, попап не закрывался */
    const onDragStopCallback = useCallback(() => {
        onDragStop?.();
        setTimeout(() => {
            setDragged(false);
        });
    }, [onDragStop, setDragged]);

    const onDragStart = useCallback(() => {
        setDragged(true);
    }, [setDragged]);

    const onSaveWrapper = useCallback(() => {
        if (cropImageParams) {
            onSave?.(cropImageParams);
        }
    }, [cropImageParams, onSave]);

    const closePopup = useCallback(() => {
        if (!dragged && cropImageParams) {
            onClose?.(cropImageParams);
        }
    }, [cropImageParams, dragged, onClose]);

    return (
        <Modal visible={visible} onClose={closePopup}>
            <ModalHeader>
                <ModalTitle>{title}</ModalTitle>
                <div>{description}</div>
            </ModalHeader>
            <div className={styles.cropImagePopupContent}>
                <ImageCrop
                    onResizeCallback={setCropImageParams}
                    onDragStart={onDragStart}
                    onDragStop={onDragStopCallback}
                    containerMaximumHeight={400}
                    {...imageCropSettings}
                />
            </div>
            <div className={styles.cropImagePopupError}>
                <ModalError visible={!!error} errorType={error} />
            </div>
            <ModalFooter>
                <div className={styles.cropImagePopupFooter}>
                    <Button onClick={closePopup} data-qa="image-crop-cancel">
                        {cancel}
                    </Button>
                    <Gap left>
                        <Button
                            kind={ButtonKind.Primary}
                            onClick={onSaveWrapper}
                            disabled={resizeInProgress}
                            loading={
                                resizeInProgress && <Loading initial={LoadingColor.Gray80} scale={LoadingScale.Small} />
                            }
                            data-qa="image-crop-save"
                        >
                            {save}
                        </Button>
                    </Gap>
                </div>
            </ModalFooter>
        </Modal>
    );
};

export default ImageCropPopup;
