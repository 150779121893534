import { FC } from 'react';
import classNames from 'classnames';

import { useSelector } from 'src/hooks/useSelector';

import SlidesContainer from 'src/components/EmployerConstructor/widgets/GalleryWidget/containers/SlidesContainer';
import { PreparedPicture } from 'src/components/EmployerConstructor/widgets/GalleryWidget/hooks/usePictures';
import { useSlides } from 'src/components/EmployerConstructor/widgets/GalleryWidget/hooks/useSlides';

import styles from './gallery-widget.less';

interface ViewModeContainerProps {
    pictures: PreparedPicture[];
}

const ViewModeContainer: FC<ViewModeContainerProps> = ({ pictures }) => {
    const [slides, activeSlide, setActiveSlide] = useSlides(pictures);
    const isMagritteEmployerPageHeaderExp = useSelector((state) => state.isMagritteEmployerPageHeaderExp);

    return (
        <div
            className={classNames(styles.widgetGallery, {
                [styles.widgetGalleryMagritte]: isMagritteEmployerPageHeaderExp,
            })}
            data-qa="widget-gallery"
        >
            <SlidesContainer slides={slides} activeSlide={activeSlide} setActiveSlide={setActiveSlide} />
        </div>
    );
};

export default ViewModeContainer;
